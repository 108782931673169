export const filterCommonValues = {
  group_by: [],

  in_country: [],
  in_city: [],
  in_browser: [],
  in_os: [],
  in_language: [],
  in_device_format: [],
  in_vendor: [],
  in_advertiser: [],
  in_campaign: [],
  in_banner: [],
  in_category: [],
  in_region: [],
  in_vpn: '0',
  in_publisher: [],
  in_custom: [],
  in_template: [],
  in_zone: [],

  ex_country: [],
  ex_region: [],
  ex_city: [],
  ex_browser: [],
  ex_os: [],
  ex_language: [],
  ex_device_format: [],
  ex_vendor: [],
  ex_advertiser: [],
  ex_campaign: [],
  ex_banner: [],
  ex_category: [],
  ex_publisher: [],
  ex_zone: [],
  ex_custom: [],
  ex_template: [],
  ex_vpn: '0',
}

export const filterCommonValuesForPublisher = {
  group_by: [],

  in_template: [],

  ex_template: [],
}
