import { createContext, useState } from 'react'
import * as React from 'react'

export const FiltersContext = createContext(undefined)

export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState([])
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false)
  const [reportType, setReportType] = useState(0)
  const [valueVpn, setterVpn] = useState('0')
  const [valueTor, setterTor] = useState('0')
  const [valueUniq, setterUniq] = useState('0')

  const value = React.useMemo(
    () => ({
      filters,
      setFilters,
      isAdvancedSearch,
      setIsAdvancedSearch,
      valueVpn,
      setterVpn,
      valueTor,
      setterTor,
      valueUniq,
      setterUniq,
      reportType,
      setReportType,
    }),
    [filters, isAdvancedSearch, valueVpn, valueTor, valueUniq, reportType],
  )

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}
