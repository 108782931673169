import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface StatisticState {
  filter: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
    reports: object
  }
  filterBalance: {
    isLoading: boolean
    success: boolean | null
    error: string | boolean | null
    reports: []
  }
}

const initialState: StatisticState = {
  filter: {
    isLoading: false,
    success: false,
    error: false,
    reports: {},
  },
  filterBalance: {
    isLoading: false,
    success: false,
    error: false,
    reports: [],
  },
}

type Report = {
  [key: string]: Record<string, any>
}

export const reducer = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    statisticGetStart: (state) => {
      state.filter.isLoading = true
      state.filter.success = false
      state.filter.error = false
    },

    statisticBalanceGetStart: (state) => {
      state.filterBalance.isLoading = true
      state.filterBalance.success = false
      state.filterBalance.error = false
    },
    statisticPublisherGetStart: (state) => {
      state.filter.isLoading = true
      state.filter.success = false
      state.filter.error = false
    },

    statisticGetSuccess: (state, action: PayloadAction<any>) => {
      const newReports: Report = action.payload.data
      const updatedReports: Report = { ...state.filter.reports }
      Object.keys(newReports).forEach((key) => {
        updatedReports[key] = newReports[key]
      })
      state.filter.reports = newReports
      state.filter.isLoading = false
      state.filter.success = true
      state.filter.error = false
    },

    statisticGetBalanceSuccess: (state, action: PayloadAction<any>) => {
      state.filterBalance.reports = action.payload
      state.filterBalance.isLoading = false
      state.filterBalance.success = true
      state.filterBalance.error = false
    },

    statisticPublisherSuccess: (state, action: PayloadAction<any>) => {
      const newReports: Report = action.payload.data
      const updatedReports: Report = { ...state.filter.reports }
      Object.keys(newReports).forEach((key) => {
        updatedReports[key] = newReports[key]
      })
      const reportKey = Object.keys(newReports)[0]
      const reportOptions = newReports[reportKey].options

      reportOptions.page = 0
      reportOptions.onPage = 10
      reportOptions.pages = 1

      state.filter.reports = newReports
      state.filter.isLoading = false
      state.filter.success = true
      state.filter.error = false
    },

    statisticGetFailure: (state, action: PayloadAction<string>) => {
      state.filter.isLoading = false
      state.filter.success = false
      state.filter.error = action.payload
    },

    statisticGetBalanceFailure: (state, action: PayloadAction<string>) => {
      state.filterBalance.isLoading = false
      state.filterBalance.success = false
      state.filterBalance.error = action.payload
    },

    statisticPublisherFailure: (state, action: PayloadAction<string>) => {
      state.filterBalance.isLoading = false
      state.filterBalance.success = false
      state.filterBalance.error = action.payload
    },
  },
})

export const {
  statisticGetStart,
  statisticBalanceGetStart,
  statisticGetSuccess,
  statisticGetBalanceSuccess,
  statisticGetFailure,
  statisticGetBalanceFailure,
  statisticPublisherGetStart,
  statisticPublisherSuccess,
  statisticPublisherFailure,
} = reducer.actions

export default reducer.reducer
