import * as React from 'react'
import { Box, Typography, CircularProgress } from '@mui/joy'

export default function ErrorPage() {
  return (
    <Box
      component="main"
      className="MainContent"
      sx={(theme) => ({
        px: { xs: 2, lg: 4 },
        pt: { xs: 2, lg: 4 },
        pb: { xs: 2, lg: 4 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: 'calc(100dvh)',
        gap: 1,
        overflowY: 'auto',
      })}
    >
      <Box
        sx={{
          border: '2px dashed var(--joy-palette-divider)',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100dvh - 48px)',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: 90,
            padding: 0,
          }}
        >
          😔
        </Typography>
        <Typography
          variant="h1"
          sx={{
            fontSize: 26,
          }}
        >
          404 :(
        </Typography>
      </Box>
    </Box>
  )
}

export function LoadingPage() {
  return (
    <Box
      sx={() => ({
        px: { xs: 2, lg: 4 },
        pt: { xs: 2, lg: 4 },
        pb: { xs: 2, lg: 4 },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100dvh',
        gap: 1,
        overflowY: 'auto',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100dvh - 48px)',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  )
}
