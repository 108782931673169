import React, { useState } from 'react'
import { Box } from '@mui/joy'
import Form from './Form'
import Submit from './Submit'

import { toast } from '../../../layout/JoyToaster'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../store'
import { usersAdd } from '../../../../store/users'
import { useCookies } from 'react-cookie'
import { history } from '../../../../store/utils/history'
import { wait } from '../../../../utils'

const Add = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const defaultFormValues = {
    email: '',
    active: true,
    role_id: '',
    password: '',
    statistic_params: {
      // поля advertiser, campaign убраны, так как настраиваем фильтры по баннеру
      // advertiser: true,
      // campaign: true,
      banner: true,
      zone: true,
      // поля publisher убраны, так как настраиваем фильтры по баннеру
      // publisher: true,
      country: true,
      region: true,
      city: true,
      browser_name: true,
      os_name: true,
      CPdomain: true,
      CPhost: true,
      language: true,
      template: true,
      vpn: true,
      device_format: true,
      device_vendor: true,
      spend: true,
      impressions_count: true,
      clicks_count: true,
    },
  }

  const allowedPublisherStatisticParams = {
    country: true,
    region: true,
    city: true,
    CPdomain: true,
    template: true,
  }

  const [, setCookie] = useCookies(['reload'])
  const [formValues, setFormValues] = useState(defaultFormValues)

  const handlerForm = async (event) => {
    event.preventDefault()
    let dataToSend = { ...formValues }
    if (formValues.role_id === 4) {
      dataToSend = {
        ...dataToSend,
        statistic_params: { ...allowedPublisherStatisticParams },
      }
    }

    dispatch(usersAdd(dataToSend)).then(async (result) => {
      if (result) {
        toast.success(t('add_user_successfully'))
        await wait(100)
        history.push({
          pathname: `/users/edit/${result.user_id}`,
        })
        await wait(200)
        window.location.reload()
      } else {
        toast.error(t('error'))
      }

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  const values = { set: setFormValues, arr: formValues }

  return (
    <Box
      sx={{
        maxWidth: 'clamp(450px, 100dvw, 900px)',
        width: '100%',
        overflowY: 'auto',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'var(--joy-palette-background-surface)',
      }}
    >
      <form onSubmit={handlerForm}>
        <Form values={values} />
        <Submit values={values} />
      </form>
    </Box>
  )
}

export default Add
