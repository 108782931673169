import React from 'react'

import { Button, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Submit = () => {
  const { t } = useTranslation()
  const isLoading = useSelector(
    (state) => state.custom.customParamsGetAllData?.isLoading,
  )

  return (
    <Space
      align="center"
      style={{
        width: '100%',
        justifyContent: 'end',
        padding: '0.5rem 1.2rem',
      }}
    >
      <Form.Item>
        <Button
          size="small"
          loading={isLoading}
          type="primary"
          htmlType={'submit'}
          variant="solid"
        >
          {t('edit_advertiser_submit')}
        </Button>
      </Form.Item>
    </Space>
  )
}

export default Submit
