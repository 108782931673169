import { useEffect } from 'react'

import { Box, CircularProgress } from '@mui/joy'
import { useParams } from 'react-router-dom'
import { useBnr } from '../../../../hooks/banners'
import api from '../../../../../api'

import ControlBar from './ControlBar'
import Form from './Form'
import { useSelector } from 'react-redux'

const Edit = ({ banner_id }) => {
  const { bnr, replaceBanner } = useBnr()
  const { pageId, campaignId } = useParams()

  const advertiserId = banner_id ? null : parseInt(pageId, 10)
  const paramBannerId = banner_id ? banner_id : parseInt(campaignId, 10)

  const stateBanners = useSelector((state) => state.banners.allData.bannersById)

  useEffect(() => {
    const isValid = !!bnr
      ? !bnr.banner_id || bnr.banner_id !== paramBannerId
      : false

    if (!banner_id && isValid && advertiserId > 0 && paramBannerId > 0) {
      api.bannersGet
        .get({
          banner_id: paramBannerId,
          advertiser_id: advertiserId,
        })
        .then((result) => {
          if (!result.data.error) {
            replaceBanner(result.data.rows)
          }
        })
    } else if (banner_id) {
      const banner = stateBanners[banner_id]
      if (banner) {
        replaceBanner(banner)
      }
    }

    return () => {
      if (banner_id) {
        replaceBanner({})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner_id, paramBannerId, advertiserId, stateBanners])

  const showContent =
    !!bnr &&
    Object.keys(bnr).length > 0 &&
    (bnr.banner_id === paramBannerId ||
      (!!banner_id && bnr.banner_id === banner_id))

  return showContent ? (
    <Box sx={{ width: '100%' }}>
      <ControlBar />
      <Form />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20dvh',
      }}
    >
      <CircularProgress size="sm" />
    </Box>
  )
}

export default Edit
