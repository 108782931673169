import React from 'react'

import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/joy'

import { DashboardRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import CaseTypes from './CaseTypes'

export const ViewSettingsBtn = (props) => (
  <IconButton
    size="sm"
    variant="plain"
    onClick={props.onClick}
    className={'case-add-btn'}
    sx={{
      minHeight: '25px',
      minWidth: '25px',
      paddingLeft: '1.5px',
      paddingRight: '1.5px',
      marginRight: '4px',
      borderRadius: '50%',
    }}
  >
    <DashboardRounded
      sx={{
        fontSize: 15,
      }}
    />
  </IconButton>
)

export const ViewSettings = ({
  open,
  rotation,
  current,
  setOpen,
  template_type,
}) => {
  const { t } = useTranslation()

  const handleClose = () => {
    const currentTargets = current.val.targets
    let changed = false

    for (const key in currentTargets) {
      if (
        Array.isArray(currentTargets[key]) &&
        currentTargets[key].length === 0
      ) {
        delete currentTargets[key]
        changed = true
      }
    }

    if (changed) {
      current.set(current.val)
    }

    const clearedRotation = rotation.val.map((item) => {
      if (item.item_id !== current.val.item_id) return item

      const newTargets = { ...item.targets }
      let updated = false

      for (const key in newTargets) {
        const targets = newTargets[key]
        if (Array.isArray(targets) && targets.length === 0) {
          delete newTargets[key]
          updated = true
        }
      }

      return updated ? { ...item, targets: newTargets } : item
    })

    rotation.set(clearedRotation)

    setOpen(false)
  }

  return (
    <>
      <Modal
        open={!!open}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ModalDialog
          sx={{ width: '1100px', maxHeight: '800px', minHeight: '370px' }}
          variant="outlined"
          role="alertdialog"
        >
          <DialogTitle>
            {t('target')} [{current.val.item_id}]
          </DialogTitle>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Divider sx={{ mt: 1 }} />
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CaseTypes
              rotation={rotation}
              current={current}
              template_type={template_type}
            />
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}
