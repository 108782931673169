import React, { useEffect, useState } from 'react'
import { Box, Divider, Typography, Chip } from '@mui/joy'
import { Button } from 'antd'

import OptionChips from './OptionChips'

import { useReport } from '../../../../../../hooks/report'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../../store'
import { rotationTarget } from '../../../../../../../store/rotations'
import { filterRequest } from '../../../../../../../store/statistic'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'

dayjs.extend(utc)
const dateFormat = 'YYYY-MM-DD HH'

const OptionBar = ({ name }) => {
  const { t } = useTranslation()
  const { updateReport } = useReport()
  const dispatch = useAppDispatch()

  const [changes, setChanges] = useState(0)
  const user_id = useSelector(
    (state) => state.auth.profileData.profile?.user_id,
  )
  const language = useSelector((state) => state.rotations.target.rows?.lang)
  const deviceText = t('device', { returnObjects: true })
  const connectionTypeText = t('connection_type', { returnObjects: true })
  const userTypesText = t('user_types', { returnObjects: true })
  const groupByText = t('group_by', { returnObjects: true })

  useEffect(() => {
    if (!language) {
      dispatch(rotationTarget())
    }
  }, [dispatch, language])

  const { report } = useReport()
  const {
    options: current = {},
    options: { openOptions = false },
  } = report[name] || {}

  const filters = Object.entries(current)
    .map(([key, item]) => {
      const isValid = ![
        'page',
        'pages',
        'search',
        'openOptions',
        'columns',
        'group_by',
      ].includes(key)

      if (isValid) {
        if (Array.isArray(item) && item.length > 0) {
          return OptionChips({
            name,
            items: item,
            category: key,
            deviceText,
            connectionTypeText,
            userTypesText,
            language,
            onChange: () => {
              setChanges(changes + 1)
            },
          })
        }
      }

      return false
    })
    .filter((i) => !!i)

  const groupBy =
    current.group_by.length &&
    OptionChips({
      name,
      items: current.group_by,
      category: 'group_by',
      groupByText,
      language,
      onChange: () => {
        setChanges(changes + 1)
      },
    })

  const renderDatePeriod = () => {
    const dateFrom = current.date_from
      ? dayjs(current.date_from)
      : dayjs().utc()

    const dateTo = current.date_to ? dayjs(current.date_to) : dayjs().utc()

    return (
      <>
        <Typography level="title-sm" sx={{ mt: 1, fontSize: 13 }}>
          {t('date_from')}
        </Typography>

        <Typography level="body-sm" sx={{ fontSize: 13 }}>
          {dateFrom.format(dateFormat)}
        </Typography>

        <Typography level="title-sm" sx={{ mt: 1, fontSize: 13 }}>
          {t('date_to')}
        </Typography>

        <Typography level="body-sm" sx={{ fontSize: 13 }}>
          {dateTo.format(dateFormat)}
        </Typography>
      </>
    )
  }

  const renderAdditionalFields = () => {
    const fields = [
      { key: 'in_vpn', label: 'VPN' },
      { key: 'ex_vpn', label: 'VPN' },
      { key: 'unique', label: t('unique') },
      { key: 'in_tor', label: 'TOR' },
      { key: 'ex_tor', label: 'TOR' },
    ]

    const hasVisibleFields = fields.some((field) => current[field.key] === '1')

    if (!hasVisibleFields) return null

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Divider sx={{ mt: 1 }} />
        <Typography level="title-sm" sx={{ mt: 1, fontSize: 13 }}>
          {t('additional_fields')}
        </Typography>
        {fields.map((field) => {
          const value = current[field.key] || '0'
          if (value !== '1') return null

          const isExcluded = field.key.startsWith('ex_')
          const color = isExcluded ? 'danger' : 'success'

          return (
            <>
              <Chip key={field.key} variant="soft" size="sm" color={color}>
                {isExcluded
                  ? `${field.label}: ${t('excluded')}`
                  : `${field.label}: ${t('included')}`}
              </Chip>
            </>
          )
        })}
      </Box>
    )
  }

  const onApply = () => {
    dispatch(
      filterRequest({
        user_id,
        report_id: name,
        options: current,
      }),
    ).then((res) => {
      updateReport(name, res[name])
    })
  }

  return (
    <>
      {!!openOptions && (
        <Box
          sx={{
            mb: 1,
            pb: 1,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: 'var(--joy-palette-background-surface)',
            borderBottom: '1px solid',
            borderColor:
              'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
            }}
          >
            <Typography level="body-md">{t('period')}:</Typography>
            {renderDatePeriod()}
          </Box>
          <Divider sx={{ mt: 1 }} />

          {/* Existing Group By Section */}
          {!!groupBy.length && (
            <>
              <Typography level="title-sm" sx={{ mt: 1, fontSize: 13 }}>
                {t('groupby')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {groupBy}
              </Box>
            </>
          )}
          {!!filters.length && (
            <>
              {!!groupBy.length && <Divider sx={{ mt: 1 }} />}
              <Typography level="title-sm" sx={{ mt: 1, fontSize: 13 }}>
                {t('filters')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                {filters}
              </Box>
            </>
          )}

          {/* Additional Fields Section */}
          <>{renderAdditionalFields()}</>
          {changes > 0 && (
            <>
              {(!!filters.length || !!groupBy.length) && (
                <Divider sx={{ mt: 2 }} />
              )}
              <Box
                sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 1 }}
              >
                <Button
                  size="small"
                  type={'primary'}
                  onClick={onApply}
                  variant="solid"
                  sx={{ ml: 2 }}
                >
                  {t('apply')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default OptionBar
