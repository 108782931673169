import { useEffect, useMemo, useState } from 'react'

import { Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../../../store'
import { customParamGetAll } from '../../../../../../store/custom'
import { useFilter } from '../../../../../hooks/filter'

const EXCLUDED_FILTERS_GENERAL = new Set([
  'connection_type',
  'publisher',
  'referer',
  'custom',
])

const EXCLUDED_FILTERS_ADVANCED = new Set(['template'])

const GroupBy = ({ isAdvancedSearch, reportType }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { filter, replaceItemFromFilter } = useFilter()
  const user = useSelector((state) => state.auth.profileData.profile)

  const isUserPublisher = user?.role_id === 4

  const groupByText = isUserPublisher
    ? t('publisher_group_by', { returnObjects: true })
    : t('group_by', { returnObjects: true })

  const isFilterTypeBalance = reportType === 2
  const [groupBy, setGroupBy] = useState(filter.group_by || [])
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )

  const cp = useSelector((state) => state.custom.customParamsGetAllData?.rows)

  const excludedFilters = useMemo(() => {
    const filters = new Set(
      isAdvancedSearch ? EXCLUDED_FILTERS_ADVANCED : EXCLUDED_FILTERS_GENERAL,
    )

    if (isFilterTypeBalance) {
      filters.add('advertiser')
      filters.add('hour')
      filters.add('month')
    }

    return filters
  }, [isAdvancedSearch, isFilterTypeBalance])

  useEffect(() => {
    if (!cp.length) {
      dispatch(customParamGetAll())
    }
  }, [dispatch, cp])

  useEffect(() => {
    if (!filter.group_by.length) {
      setGroupBy([])
      return
    }
    setGroupBy((prev) => {
      const newValues = prev.filter((option) => !excludedFilters.has(option))
      replaceItemFromFilter('group_by', newValues)
      return newValues
    })
  }, [excludedFilters, replaceItemFromFilter, filter.group_by.length])

  const options = useMemo(() => {
    let opts = Object.entries(groupByText).map(([key, value]) => ({
      value: key,
      label: value,
    }))

    opts = opts.filter((option) => !excludedFilters.has(option.value))

    if (cp) {
      cp.forEach((i) => opts.push({ value: `CP${i.param_key}`, label: i.name }))
    }

    return opts.filter((e) => {
      switch (e.value) {
        case 'browser':
          return statParams.browser_name
        case 'operating':
          return statParams.os_name
        case 'format':
          return statParams.device_format
        case 'vendor':
          return statParams.device_vendor
        default:
          return statParams[e.value] !== false
      }
    })
  }, [cp, excludedFilters, groupByText, statParams])

  return (
    <>
      <Select
        defaultValue={groupBy}
        maxTagCount={'responsive'}
        allowClear
        mode={'multiple'}
        style={{ flex: 1 }}
        maxCount={isFilterTypeBalance ? 1 : undefined}
        loading={!options.length}
        options={options}
        value={isFilterTypeBalance ? [groupBy[0]].filter(Boolean) : groupBy}
        onChange={(newValue) => {
          replaceItemFromFilter('group_by', newValue)
          setGroupBy(newValue)
        }}
      />
    </>
  )
}

export default GroupBy
