import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input, InputNumber, Card, Space, Button, Empty } from 'antd'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Add, AirlineStopsRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'

const cleanDomain = (value) => value.replace(/^(https?:\/\/)?(www\.)?/, '')

const DomainReplacementBlock = ({ form }) => {
  const { t } = useTranslation()
  const [, forceUpdate] = useState()
  const [isEmpty, setIsEmpty] = useState(true)
  const [index, setIndex] = useState(0)
  const [percentTotals, setPercentTotals] = useState({})

  const calculateTotalPercent = useCallback(
    (fieldIndex) => {
      const mappingInfo = form.getFieldValue('mapping_info') || []
      const replacements = mappingInfo[fieldIndex]?.replacement_domains || []

      const totalPercent = replacements.reduce(
        (sum, rep) =>
          sum + (rep.domain_name && rep.percentage ? rep.percentage || 0 : 0),
        0,
      )

      setPercentTotals((prev) => ({ ...prev, [fieldIndex]: totalPercent }))
      return totalPercent
    },
    [form],
  )

  useEffect(() => {
    const mappingInfo = form.getFieldValue('mapping_info') || []
    setIsEmpty(mappingInfo.length === 0)

    mappingInfo.forEach((mapping, idx) => {
      calculateTotalPercent(idx)
    })
  }, [calculateTotalPercent, form])

  const handleFormChange = () => {
    const currentFormData = form.getFieldsValue()
    const mappingInfo = currentFormData.mapping_info || []
    setIsEmpty(mappingInfo.length === 0)

    mappingInfo.forEach((mapping, idx) => {
      calculateTotalPercent(idx)
    })

    forceUpdate({})
  }

  const handlePercentageChange = (fieldIndex, subFieldIndex) => {
    const mappingInfo = form.getFieldValue('mapping_info') || []
    const replacements = mappingInfo[fieldIndex]?.replacement_domains || []

    const totalPercent = calculateTotalPercent(fieldIndex)

    const currentValue = replacements[subFieldIndex]?.percentage || 0

    if (totalPercent > 100) {
      const otherFieldsTotal = totalPercent - currentValue
      const maxAllowedForCurrent = 100 - otherFieldsTotal

      form.setFieldValue(
        [
          'mapping_info',
          fieldIndex,
          'replacement_domains',
          subFieldIndex,
          'percentage',
        ],
        Math.max(0, maxAllowedForCurrent),
      )

      setTimeout(() => calculateTotalPercent(fieldIndex), 0)
    }

    setTimeout(handleFormChange, 0)
  }

  const handleAddMapping = () => {
    const currentMappings = form.getFieldValue('mapping_info') || []
    const newIndex = currentMappings.length

    setPercentTotals((prev) => ({ ...prev, [newIndex]: 0 }))

    form.setFieldValue('mapping_info', [
      ...currentMappings,
      { replacement_domains: Array(5).fill({}) },
    ])

    setTimeout(handleFormChange, 0)
  }

  return (
    <Accordion
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <AirlineStopsRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('domain_mapping')}</Box>
      </AccordionSummary>
      <AccordionDetails>
        <Form.List name="mapping_info">
          {(fields, { add, remove }) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}
            >
              {isEmpty && (
                <div style={{ textAlign: 'center', marginBottom: 16 }}>
                  <Empty
                    description={t('no_data')}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}

              {fields.map((field) => {
                const totalPercent = percentTotals[field.name] || 0

                return (
                  <Card
                    size="small"
                    title={
                      <Space>
                        {t('mapping')} {field.name + 1}
                      </Space>
                    }
                    key={field.key}
                    extra={
                      <Space>
                        <span
                          style={{
                            color:
                              totalPercent > 100
                                ? 'red'
                                : totalPercent === 100
                                  ? 'green'
                                  : '#fa8c16',
                            fontWeight: 'bold',
                          }}
                        >
                          {totalPercent}%
                        </span>
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name)

                            const newPercentTotals = { ...percentTotals }
                            delete newPercentTotals[field.name]
                            setPercentTotals(newPercentTotals)
                            setTimeout(handleFormChange, 0)
                          }}
                        />
                      </Space>
                    }
                  >
                    <Form.Item
                      label={t('original_domain')}
                      name={[field.name, 'original_domain']}
                      rules={[
                        { required: true, message: t('error_source_domain') },
                      ]}
                    >
                      <Input
                        onBlur={(e) =>
                          form.setFieldValue(
                            ['mapping_info', field.name, 'original_domain'],
                            cleanDomain(e.target.value),
                          )
                        }
                        onChange={() => setTimeout(handleFormChange, 0)}
                      />
                    </Form.Item>

                    <Form.Item label={t('replacements')}>
                      <Form.List name={[field.name, 'replacement_domains']}>
                        {(subFields) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: 16,
                            }}
                          >
                            {Array.from({ length: 5 }).map((_, index) => {
                              const prevDomain = form.getFieldValue([
                                'mapping_info',
                                field.name,
                                'replacement_domains',
                                index - 1,
                                'domain_name',
                              ])
                              const prevPercent = form.getFieldValue([
                                'mapping_info',
                                field.name,
                                'replacement_domains',
                                index - 1,
                                'percentage',
                              ])
                              const isEnabled =
                                index === 0 || (prevDomain && prevPercent > 0)

                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: 'inline-flex',
                                    gap: '0.5rem',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Form.Item
                                    noStyle
                                    name={[index, 'domain_name']}
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      flexGrow: '1',
                                    }}
                                  >
                                    <Input
                                      placeholder={`${t('domain_for_replace')} ${index + 1}`}
                                      disabled={!isEnabled}
                                      style={{ width: '100%', flexGrow: '1' }}
                                      onBlur={(e) =>
                                        form.setFieldValue(
                                          [
                                            'mapping_info',
                                            field.name,
                                            'replacement_domains',
                                            index,
                                            'domain_name',
                                          ],
                                          cleanDomain(e.target.value),
                                        )
                                      }
                                      onChange={() =>
                                        setTimeout(handleFormChange, 0)
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    noStyle
                                    name={[index, 'percentage']}
                                    style={{ width: 'auto' }}
                                  >
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      placeholder="0"
                                      disabled={!isEnabled}
                                      addonAfter={<span>%</span>}
                                      onChange={() =>
                                        handlePercentageChange(
                                          field.name,
                                          index,
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                )
              })}
              <Button
                type="dashed"
                onClick={handleAddMapping}
                block
                icon={<PlusOutlined />}
              >
                {t('add')} {t('mapping')}
              </Button>
            </div>
          )}
        </Form.List>
      </AccordionDetails>
    </Accordion>
  )
}

export default DomainReplacementBlock
