import React, { createContext, useContext, useState } from 'react'
import { filterCommonValues } from '../constants'
export const FilterContext = createContext()

const defaultValues = {
  order_by: 'desc',
  report_type: '',
  in_tor: '0',
  unique: '0',
  in_ip_range: [],
  in_isp: [],
  in_connection_type: [],
  in_resource_type: [],
  in_referer: [],

  order_by_key: 'count',
  page: 0,
  pages: 1,
  onPage: 10,
  search: '',
  openOptions: false,
  columns: [],

  new: '0',

  ex_ip_range: [],
  ex_isp: [],
  ex_connection_type: [],
  ex_resource_type: [],
  ex_referer: [],
  ex_tor: '0',
}

export function FilterProvider({ children }) {
  const [filter, setFilter] = useState({
    ...filterCommonValues,
    ...defaultValues,
    date_from: '',
    date_to: '',
  })

  const updateFilter = (key, newValue) => {
    setFilter((current) => {
      if (current.hasOwnProperty(key) && Array.isArray(current[key])) {
        if (Array.isArray(newValue)) {
          let updatedArray = [...current[key]]
          newValue.forEach((newItem) => {
            if (typeof newItem === 'string' || typeof newItem === 'number') {
              if (!updatedArray.includes(newItem)) {
                updatedArray.push(newItem)
              }
            } else if (typeof newItem === 'object' && newItem !== null) {
              if (!updatedArray.some((item) => item.value === newItem.value)) {
                updatedArray.push(newItem)
              }
            }
          })
          return { ...current, [key]: updatedArray }
        } else {
          if (typeof newValue === 'string' || typeof newValue === 'number') {
            if (!current[key].includes(newValue)) {
              return { ...current, [key]: [...current[key], newValue] }
            }
          } else if (typeof newValue === 'object' && newValue !== null) {
            if (!current[key].some((item) => item.value === newValue.value)) {
              return { ...current, [key]: [...current[key], newValue] }
            }
          }
        }
      } else if (typeof newValue === 'string' || typeof newValue === 'number') {
        current[key] = newValue
        return { ...current, [key]: newValue }
      }
      return current
    })
  }

  const replaceItemFromFilter = (key, value) => {
    setFilter((current) => {
      if (current.hasOwnProperty(key) && Array.isArray(current[key])) {
        current[key] = value
      }

      return current
    })
  }

  const replaceFilter = (newFilter) => {
    setFilter(newFilter)
  }

  const resetFilter = () => {
    setFilter((current) => ({
      ...current,
      ...filterCommonValues,
      ...defaultValues,
    }))
  }

  return (
    <FilterContext.Provider
      value={{
        filter,
        updateFilter,
        replaceFilter,
        replaceItemFromFilter,
        resetFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export function useFilter() {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider')
  }
  return context
}
