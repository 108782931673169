import { Col, Row, Table, Empty, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress } from '@mui/joy'
import { formatReadableNumericString } from '../../../../../utils'
import { useFilter } from '../../../../hooks/filter'

export const FilterBalanceContainer = () => {
  const { t } = useTranslation()
  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const { filter } = useFilter()
  const advertisers = useSelector(
    (state) => state.statistic.filterBalance.reports,
  )
  const isLoading = useSelector(
    (state) => state.statistic.filterBalance.isLoading,
  )

  const isGroupByDate = new Set(filter?.group_by)
  
  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : [])
  }

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: t('advertiser'),
      dataIndex: 'advertiser_name',
      key: 'advertiser_name',
      width: '20%',
    },

    {
      title: t('income'),
      dataIndex: 'deposits',
      key: 'deposits',
      width: '20%',
      render: (text) => {
        return formatReadableNumericString(text)
      },
    },
    {
      title: t('expense'),
      dataIndex: 'expenses',
      key: 'expenses',
      width: '20%',
      render: (text) => {
        return formatReadableNumericString(text)
      },
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      render: (text) => {
        return formatReadableNumericString(text)
      },
    },
    {
      title: t('comment'),
      dataIndex: '',
      key: '',
      width: '20%',
    },
  ]

  const expandedColumns = [
    {
      title: '',
      dataIndex: 'custom_field',
      key: 'custom_field',
      width: '20%',
    },
    {
      title: t('income'),
      dataIndex: 'income',
      key: 'income',
      width: '20%',
      render: (text) => (
        <Box sx={{ color: '#389e0d', fontWeight: '600' }}>
          {formatReadableNumericString(text)}
        </Box>
      ),
    },
    {
      title: t('expense'),
      dataIndex: 'expense',
      key: 'expense',
      width: '20%',
      render: (text) => (
        <Box sx={{ color: '#cf1322', fontWeight: '600' }}>
          {formatReadableNumericString(text)}
        </Box>
      ),
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      key: 'balance',
      width: '20%',
      render: (text) => {
        if (isGroupByDate.has('date')) return
        return formatReadableNumericString(text)
      },
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      key: 'comment',
      width: '20%',
      ellipsis: 'true',
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
  ]

  const dataSource = advertisers.map(
    ({ advertiser_id, advertiser_name, values }) => ({
      key: advertiser_id,
      advertiser_name,
      deposits: values.deposits,
      expenses: values.expenses,
      balance: values.balance,
      details: values.details || [],
    }),
  )

  return (
    <Box
      variant="outlined"
      color="neutral"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(calc(100vh))',
        overflowX: 'auto',
        p: 2,
      }}
    >
      <Row gutter={24}>
        <Col span={24}>
          {!isLoading ? (
            <Table
              dataSource={dataSource}
              columns={columns}
              sticky={true}
              expandable={{
                expandedRowKeys,
                onExpand: handleExpand,
                rowExpandable: (record) => record.details.length > 0,
                expandedRowRender: (record) => (
                  <Table
                    dataSource={record.details.map((detail, index) => ({
                      key: `${record.key}-${index}`,
                      ...detail,
                    }))}
                    showHeader={false}
                    columns={expandedColumns}
                    pagination={false}
                    size="small"
                  />
                ),
              }}
              pagination={{
                locale: { items_per_page: `/ ${t('per_page_items')}` },
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '15', '30'],
              }}
              scroll={{ y: 55 * 10 }}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('no_data')}
                  />
                ),
              }}
              size="small"
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '101px',
              }}
            >
              <CircularProgress size="sm" />
            </Box>
          )}
        </Col>
      </Row>
    </Box>
  )
}
