import React, { useEffect, useState } from 'react'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from '@mui/joy'
import { Add, AccountBalanceWalletRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAdv } from '../../../../../hooks/advertisers'

import { BalanceContainer } from './BalanceContainer'
import { useAppDispatch } from '../../../../../../store'
import { getBudgetAdvertiser } from '../../../../../../store/advertisers'
import { useSelector } from 'react-redux'

const Balance = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { adv } = useAdv()
  const [index, setIndex] = useState(0)

  const advertiserBudgetInfo = useSelector(
    (state) => state.advertisers.advertiserBudget.budget,
  )
  const isLoading = useSelector(
    (state) => state.advertisers.advertiserBudget.isLoading,
  )

  useEffect(() => {
    dispatch(getBudgetAdvertiser({ advertiser_id: adv.advertiser_id }))
  }, [adv.advertiser_id, dispatch])

  return (
    <Accordion
      expanded={index === 0}
      onChange={(_, expanded) => {
        setIndex(expanded ? 0 : null)
      }}
    >
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <AccountBalanceWalletRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('balance_advertiser')}</Box>
      </AccordionSummary>

      <AccordionDetails>
        {!isLoading && advertiserBudgetInfo.advertiser_id ? (
          <BalanceContainer
            budgetInfo={advertiserBudgetInfo}
            advertiserId={adv.advertiser_id}
          />
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '101px',
              }}
            >
              <CircularProgress size="sm" />
            </Box>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default Balance
