import React, { useCallback, useContext } from 'react'

import { Select } from 'antd'
import { useFilter } from '../../../../../hooks/filter'
import { FiltersContext } from '../../../../../hocs/FiltersProvider'
import { useSelector } from 'react-redux'

const ReportType = ({ setIsAdvancedSearch, reportType, setReportType }) => {
  const { updateFilter, resetFilter, replaceItemFromFilter } = useFilter()
  const profile = useSelector((state) => state.auth.profileData.profile)

  const isGrantedRole = profile && [1, 2].includes(profile.role_id)

  const reportTypes = isGrantedRole
    ? [
        { value: 0, label: 'General' },
        { value: 1, label: 'Advanced' },
        { value: 2, label: 'Balance' },
      ]
    : [{ value: 0, label: 'General' }]

  const { setterVpn, setterTor, setterUniq } = useContext(FiltersContext)

  const handleChange = useCallback(
    (newValue) => {
      const setToDefault = () => {
        setterVpn('0')
        setterUniq('0')
        setterTor('0')
        resetFilter()
      }

      if (newValue === 0) {
        setToDefault()
      }

      setIsAdvancedSearch((prev) =>
        prev !== (newValue === 1) ? newValue === 1 : prev,
      )
      setReportType((prev) => (prev !== newValue ? newValue : prev))

      updateFilter('report_type', newValue)

      const isBalanceType = newValue === 2
      if (isBalanceType) {
        replaceItemFromFilter('group_by', [])
      }
    },
    [
      resetFilter,
      setIsAdvancedSearch,
      setReportType,
      setterTor,
      setterUniq,
      setterVpn,
      updateFilter,
      replaceItemFromFilter,
    ],
  )

  return (
    <Select
      defaultValue={reportType}
      maxTagCount={'responsive'}
      style={{ marginRight: '12px', flex: 1 }}
      options={reportTypes}
      onChange={handleChange}
    />
  )
}

export default ReportType
