import { Box } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Chips from './Chips'
import DateInputs from './DateInputs'
import GroupBy from './GroupBy'
import ReportType from './ReportType'
import VpnTorBtn from './Vpn'

import { Button } from 'antd'

import { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../../store'
import {
  filterPublisherRequest,
  filterRequest,
  filterTypeBalanceRequest,
} from '../../../../../../store/statistic'
import { FiltersContext } from '../../../../../hocs/FiltersProvider'
import { useFilter } from '../../../../../hooks/filter'
import { useReport } from '../../../../../hooks/report'
import { useTab } from '../../../../../hooks/tab'
import { random } from '../../../../Rotation/Edit/Modal/utils'
import {
  filterCommonValues,
  filterCommonValuesForPublisher,
} from '../../../../../constants'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'
import { toast } from '../../../../../layout/JoyToaster'

dayjs.extend(utc)

const Utility = ({
  isAdvancedSearch,
  setIsAdvancedSearch,
  reportType,
  setReportType,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { filter } = useFilter()
  const { updateReport } = useReport()
  const { updateTab, setCurrentTab } = useTab()
  const [dateRange, setDateRange] = useState([
    filter.date_from ? dayjs(filter.date_from) : dayjs().utc(),
    filter.date_to ? dayjs(filter.date_to) : dayjs().utc(),
  ])

  const user_id = useSelector(
    (state) => state.auth.profileData.profile?.user_id,
  )
  const user_role = useSelector(
    (state) => state.auth.profileData.profile?.role_id,
  )

  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )

  const isUserPublisher = user_role === 4

  const isReportTypeBalance = reportType === 2

  const { valueVpn, setterVpn, valueTor, setterTor, valueUniq, setterUniq } =
    useContext(FiltersContext)

  const runReport = () => {
    const reportKey = random()
    const reportName = random()

    setCurrentTab(reportKey)

    const filterToSend = isReportTypeBalance
      ? {
          ...Object.fromEntries(
            Object.entries(filter).filter(([key]) => key in filterCommonValues),
          ),
          group_by: String(filter.group_by || ''),
          date_from: dayjs(dateRange[0])
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
          date_to: dayjs(dateRange[1])
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss'),
        }
      : filter

    if (isReportTypeBalance) {
      dispatch(
        filterTypeBalanceRequest({
          user_id,
          report_id: reportName,
          options: filterToSend,
        }),
      ).then((result) => {
        if (result.status !== 200) {
          toast.error(result.response.data.errorText)
        }
      })
    } else if (isUserPublisher) {
      const filterToSend = {}
      for (const key in filter) {
        if (key in filterCommonValuesForPublisher) {
          filterToSend[key] = filter[key]
        }
      }

      dispatch(
        filterPublisherRequest({
          report_id: reportName,
          options: {
            ...filterToSend,
            date_from: filter.date_from
              ? dayjs(dateRange[0])
                  .minute(0)
                  .second(0)
                  .format('YYYY-MM-DD HH:mm:ss')
              : '',
            date_to: filter.date_to
              ? dayjs(dateRange[1])
                  .minute(59)
                  .second(59)
                  .format('YYYY-MM-DD HH:mm:ss')
              : '',
          },
        }),
      ).then((item) => {
        updateReport(reportName, item[reportName])
      })
    } else {
      dispatch(
        filterRequest({
          user_id,
          report_id: reportName,
          withAdvancedSearch: isAdvancedSearch,
          options: {
            ...filterToSend,
            date_from: filter.date_from
              ? dayjs(dateRange[0])
                  .minute(0)
                  .second(0)
                  .format('YYYY-MM-DD HH:mm:ss')
              : '',
            date_to: filter.date_to
              ? dayjs(dateRange[1])
                  .minute(59)
                  .second(59)
                  .format('YYYY-MM-DD HH:mm:ss')
              : '',
          },
        }),
      ).then((item) => {
        updateReport(reportName, item[reportName])
      })
    }

    updateTab(reportKey, {
      key: reportKey,
      name: reportName,
      reportType: reportType,
    })
  }

  useEffect(() => {
    if (filter.date_from && filter.date_to) {
      setDateRange([dayjs(filter.date_from), dayjs(filter.date_to)])
    }
  }, [filter.date_from, filter.date_to])

  return (
    <>
      <Chips isAdvancedSearch={isAdvancedSearch} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          px: 3,
          alignItems: 'center',
        }}
      >
        <DateInputs
          isReportTypeBalance={isReportTypeBalance}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />

        <Button
          onClick={runReport}
          type={'primary'}
          style={{ marginLeft: '8px' }}
        >
          {t('run_report')}
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center',
          py: 2,
          px: 3,
        }}
      >
        <ReportType
          reportType={reportType}
          setReportType={setReportType}
          setIsAdvancedSearch={setIsAdvancedSearch}
        />
        <GroupBy isAdvancedSearch={isAdvancedSearch} reportType={reportType} />
      </Box>

      {(statParams.vpn || isAdvancedSearch) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gridGap: 16,
            mb: 2,
            overflowX: 'auto',
          }}
        >
          {statParams.vpn && (
            <VpnTorBtn id="vpn" val={valueVpn} setter={setterVpn} />
          )}
          {isAdvancedSearch && (
            <>
              <VpnTorBtn id="unique" val={valueUniq} setter={setterUniq} />
              <VpnTorBtn id="tor" val={valueTor} setter={setterTor} />
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default Utility
