import * as React from 'react'

import {
  LocationOnRounded,
  ModeOfTravelRounded,
  SignalCellularAltRounded,
  RouterRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Accord, AccordItem } from '../../Accord'

import Isp from './Isp'
import IpRange from './IpRange'
import Locations from './Locations'
import ConnectionType from './ConnectionType'

const Ip = ({ isAdvancedSearch }) => {
  const { t } = useTranslation()
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )
  let minBlocked
  if (!statParams.country) {
    minBlocked = 'country'
  } else if (!statParams.region) {
    minBlocked = 'region'
  } else if (!statParams.city) {
    minBlocked = 'city'
  }

  const user_role = useSelector(
    (state) => state.auth.profileData.profile.role_id,
  )

  const isUserPublisher = user_role === 4

  return (
    <>
      <Accord
        items={
          <>
            {minBlocked !== 'country' && !isUserPublisher && (
              <AccordItem
                text={t('target_1_location')}
                icon={<LocationOnRounded />}
                items={<Locations minBlocked={minBlocked} />}
              />
            )}
            {isAdvancedSearch && (
              <>
                <AccordItem
                  text={t('target_1_ip_range')}
                  icon={<ModeOfTravelRounded />}
                  items={<IpRange />}
                />
                <AccordItem
                  text={t('target_1_operator')}
                  icon={<SignalCellularAltRounded />}
                  items={<Isp />}
                />
                <AccordItem
                  text={t('target_1_connection_type')}
                  icon={<RouterRounded />}
                  last={1}
                  items={<ConnectionType />}
                />
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default Ip
