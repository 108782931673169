import React, { useState } from 'react'

import FormContainer from './FormContainer'

import { useTranslation } from 'react-i18next'
import { toast } from '../../../../../layout/JoyToaster'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../../../store'
import { bannerEdit } from '../../../../../../store/banners'
import { useBnr } from '../../../../../hooks/banners'

const BaseInformation = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { bnr } = useBnr()
  const { campaignId, pageId } = useParams()
  const advertiser_id =
    campaignId && pageId ? parseInt(pageId) : bnr.advertiser_id

  const [isLoading, setIsLoading] = useState(false)

  const handlerForm = (event) => {
    event.preventDefault()
    setIsLoading(true)

    dispatch(
      bannerEdit({
        advertiser_id,
        banner_id: bnr.banner_id,
        active: bnr.active,
        name: bnr.name,
        url: bnr.url,
        creative: bnr.creative,
        metadata: bnr.metadata,
      }),
    ).then((result) => {
      if (result) {
        result
          ? toast.success(t('edit_banner_successfully'))
          : toast.error(t('error'))
      }
      setIsLoading(false)
    })
  }

  return (
    <form onSubmit={handlerForm}>
      <FormContainer isLoading={isLoading} />
    </form>
  )
}

export default BaseInformation
