import React, { useEffect, useState } from 'react'
import { Sheet, Divider } from '@mui/joy'

import MainList from './List'
import Search from './Search'
import Header from './Header'

import { useAppDispatch } from '../../../../store'
import { getAllAdvertisers } from '../../../../store/advertisers'
import { getAllCategories } from '../../../../store/categories'

export default function Sidebar() {
  const dispatch = useAppDispatch()
  const [searchCandidate, setSearchCandidate] = useState('')
  const [advertisersListCategory, setAdvertisersListCategory] = useState(null)

  useEffect(() => {
    dispatch(getAllCategories())
    if (advertisersListCategory === null) {
      dispatch(getAllAdvertisers())
    } else {
      dispatch(
        getAllAdvertisers({
          active: advertisersListCategory,
          category: advertisersListCategory,
        }),
      )
    }
  }, [advertisersListCategory, dispatch])

  return (
    <Sheet
      sx={{
        borderRight: '1px solid',
        borderColor: 'divider',
        transition: 'transform 0.4s',
        height: 'calc(100dvh)',
        top: 0,
        pt: 1,
        overflowY: 'auto',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 999,
      }}
    >
      <Header />
      <Divider />
      <Search
        setSearchCandidate={setSearchCandidate}
        setAdvertisersListCategory={setAdvertisersListCategory}
      />
      <MainList searchCandidate={searchCandidate} />
    </Sheet>
  )
}
