import { Form } from 'antd'

import { toast } from '../../../layout/JoyToaster'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../store'
import { useTranslation } from 'react-i18next'
import { customParamEdit } from '../../../../store/custom'

import FormContent from './Form'
import Submit from './Submit'

const BaseInformation = ({ rows }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [, setCookie] = useCookies(['reload'])

  const cleanFormData = (values) => {
    const cleanedMappingInfo = values.mapping_info
      ?.map((mapping) => ({
        ...mapping,
        replacement_domains: mapping.replacement_domains
          .filter((rep) => rep.domain_name?.trim())
          .map((rep) => ({
            ...rep,
            percentage: rep.percentage || 0,
          })),
      }))
      .filter((mapping) => mapping.replacement_domains.length > 0)

    return { ...values, mapping_info: cleanedMappingInfo }
  }

  const onFinishFailed = () => {
    toast.error(
      form.getFieldsError().filter(({ errors }) => errors.length)?.[0]
        ?.errors[0],
    )
  }
  const onFinish = (values) => {
    const cleanedData = cleanFormData(values)

    dispatch(
      customParamEdit({
        param_id: rows.param_id,
        param_value: values.param_value,
        param_key: values.param_key,
        name: values.name,
        ...cleanedData,
      }),
    ).then((result) => {
      if (result?.status !== 200) {
        toast.error(result.response.data.errorText)
      } else {
        toast.success(t('edit_custom_param_successfully'))
      }

      setCookie('reload', new Date().getTime(), { path: '/' })
    })
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinishFailed={onFinishFailed}
      onFinish={onFinish}
      initialValues={{
        name: rows.name,
        param_value: rows.param_value,
        param_key: rows.param_key,
        mapping_info: rows.mapping_info,
      }}
    >
      <FormContent form={form} />
      <Submit />
    </Form>
  )
}

export default BaseInformation
