import React, { useState } from 'react'

import {
  Box,
  Sheet,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/joy'

import { SourceRounded, DeleteForeverRounded } from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../../store'
import { bannerDel } from '../../../../../store/banners'
import { useBnr } from '../../../../hooks/banners'
import { Macros, MacrosBtn } from '../../Macros'
import DeleteDialog from '../../../../layout/DeleteDialog'

const ControlBar = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { bnr } = useBnr()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const campaignId = parseInt(useParams().campaignId)
  const advertiserId = parseInt(useParams().pageId)
  const bannerId = parseInt(useParams().campaignId)
  const [openMacros, setOpenMacros] = useState(false)
  const navigate = useNavigate()

  const deleteBanner = () => {
    dispatch(
      bannerDel({
        advertiser_id: advertiserId,
        banner_id: bannerId,
      }),
    ).then((result) => {
      if (result) {
        setOpenDeleteDialog(false)
        navigate('/advertisers')
      }
    })
  }

  const RemoveCampaign = () => (
    <Tooltip
      arrow
      color="primary"
      placement="top"
      title={t('remove_banner')}
      variant="solid"
    >
      <IconButton size="sm" onClick={setOpenDeleteDialog}>
        <DeleteForeverRounded sx={{ fontSize: '20px' }} color="danger" />
      </IconButton>
    </Tooltip>
  )

  return (
    <>
      <Macros open={openMacros} setOpen={setOpenMacros} />
      <DeleteDialog
        open={{ set: setOpenDeleteDialog, value: openDeleteDialog }}
        description={t('delete_dialog_remove_banner')}
        onClick={(_) => {
          deleteBanner()
        }}
      />
      <Sheet
        variant="outlined"
        sx={{
          border: 'none',
          transition: 'transform 0.4s',
          overflow: 'hidden',
          px: 2,
          py: 1.5,
          minHeight: '58px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor:
            'var(--variant-softBg, var(--joy-palette-primary-softBg, var(--joy-palette-primary-100, #E3EFFB)))',
        }}
      >
        <Typography
          level="title-md"
          sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
        >
          <SourceRounded sx={{ mr: '10px' }} />
          {'name' in bnr ? bnr.name : <CircularProgress size="sm" />}
        </Typography>

        <Box sx={{ gridGap: '10px', display: 'flex' }}>
          <MacrosBtn onClick={setOpenMacros} />
          {campaignId ? <RemoveCampaign /> : null}
        </Box>
      </Sheet>
    </>
  )
}

export default ControlBar
