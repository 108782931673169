import {
  Modal,
  ModalDialog,
  ModalClose,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/joy'

import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Panorama,
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import EditBanner from '../../../Advertisers/Banners/Edit/index'
import { RootState, useAppDispatch } from '../../../../../store'
import { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { bannerGetMultipleBanners } from '../../../../../store/banners'
import { Button } from 'antd'

type BannerButtonProps = {
  onClick: () => void
}

export const BannerButton: FC<BannerButtonProps> = (props) => (
  <IconButton
    size="sm"
    variant="plain"
    onClick={props.onClick}
    className={'case-add-btn'}
    sx={{
      minHeight: '25px',
      minWidth: '25px',
      paddingLeft: '1.5px',
      paddingRight: '1.5px',
      marginRight: '4px',
      borderRadius: '50%',
    }}
  >
    <Panorama
      sx={{
        fontSize: 15,
      }}
    />
  </IconButton>
)

type BannerProps = {
  open: boolean
  bannerIds: number[]
  bannerId: number
  currentIdInTree: string
  setOpen: (open: boolean) => void
  goToNextBanner: () => void
  goToPreviousBanner: () => void
}

export const Banner: FC<BannerProps> = ({
  open,
  bannerIds,
  bannerId,
  currentIdInTree,
  setOpen,
  goToNextBanner,
  goToPreviousBanner,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const stateBanners = useSelector(
    // TODO: create useAppSelector with root type. Not done now because it's a lot of work
    (state: RootState) => state.banners.allData.bannersById,
  )
  const isBannerMapEmpty = Object.keys(stateBanners).length === 0

  useEffect(() => {
    if (open) {
      if (isBannerMapEmpty) {
        dispatch(bannerGetMultipleBanners({ ids: bannerIds }))
      } else {
        const bannerIdsForRequest = bannerIds.filter(
          (id: number) => !stateBanners[id],
        )
        if (bannerIdsForRequest.length > 0) {
          dispatch(bannerGetMultipleBanners({ ids: bannerIdsForRequest }))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bannerId, bannerIds])

  return (
    <>
      <Modal
        open={!!open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <ModalDialog
          sx={{ width: '1100px', maxHeight: '800px', minHeight: '370px' }}
          variant="outlined"
          role="alertdialog"
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
            <Button type='text' onClick={goToPreviousBanner}>
              <KeyboardDoubleArrowLeft
                sx={{
                  fontSize: 20,
                  ':hover': { cursor: 'pointer', opacity: '0.5' },
                }}
              />
            </Button>

            <span>
              {t('banner_settings')} [{currentIdInTree}]
            </span>
            <Button type='text' onClick={goToNextBanner}>
              <KeyboardDoubleArrowRight
                sx={{
                  fontSize: 20,
                  ':hover': { cursor: 'pointer', opacity: '0.5' },
                }}
              />
            </Button>
          </DialogTitle>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Divider sx={{ mt: 1 }} />
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EditBanner banner_id={bannerId} />
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  )
}
