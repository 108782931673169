import React, { useEffect, useState } from 'react'

import {
  Box,
  Sheet,
  List,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/joy'

import {
  Add,
  SourceRounded,
  PermMediaRounded,
  TrackChangesRounded,
  InterestsRounded,
  LandscapeRounded,
  MediationRounded,
  DeleteRounded,
  CopyAllRounded,
  SkipNextRounded,
  KeyboardArrowRightRounded,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'

import Nestable from 'react-nestable'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ViewSettings, ViewSettingsBtn } from './Modal'
import { random, addToRotation, removeItemRotation } from './Modal/utils'

import CurrentTargetsTooltip from './CurrentTargetsTooltip'
import { useRtt } from '../../../hooks/rotations'
import { Banner, BannerButton } from './Modal/Banner'
import { BannerProvider } from '../../../hooks/banners'

const TargetContainer = ({ r, template_type }) => {
  const { t } = useTranslation()
  const [currentItem, setCurrentItem] = useState(false)
  const [openTargets, setOpenTargets] = useState(false)
  const [rotation, setRotation] = useState([])
  const { rtt } = useRtt()
  const [bannerIds, setBannerIds] = useState([])

  const [openEditRotation, setOpenEditBanner] = useState(false)
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const [rotationBannersByBannerId, setRotationBannersByBannerId] =
    useState(null)

  const pageId = parseInt(useParams().pageId)

  const typesIcon = {
    case: <SourceRounded sx={{ fontSize: 16 }} />,
    interests: <InterestsRounded sx={{ fontSize: 16 }} />,
    retarget: <TrackChangesRounded sx={{ fontSize: 16 }} />,
    banner: <LandscapeRounded sx={{ fontSize: 16 }} />,
    advertiser: <PermMediaRounded sx={{ fontSize: 16 }} />,
    wrapper: <MediationRounded sx={{ fontSize: 16 }} />,
  }

  const getRotationBannerIds = (rotation) => {
    const tempUnique = new Set()
    const stack = [...rotation]
    const itemsByBannerId = new Map()

    while (stack.length > 0) {
      const item = stack.pop()

      if (item.type === 'banner') {
        tempUnique.add(item.content_id)
        itemsByBannerId.set(item.content_id, item)
      }

      if (item.items.length > 0) {
        stack.push(...item.items)
      }
    }

    return { bannerIds: Array.from(tempUnique), itemsByBannerId }
  }

  const onOpenBanner = (item) => {
    setOpenEditBanner(true)
    setCurrentItem(item)

    const bannerIndex = bannerIds.findIndex((id) => id === item.content_id)

    if (bannerIndex !== -1) {
      setCurrentBannerIndex(bannerIndex)
    }
  }

  const goToPreviousBanner = () => {
    if (currentBannerIndex === 0) {
      setCurrentItem(
        rotationBannersByBannerId.get(bannerIds[bannerIds.length - 1]),
      )
      setCurrentBannerIndex(bannerIds.length - 1)
    } else {
      setCurrentItem(
        rotationBannersByBannerId.get(bannerIds[currentBannerIndex - 1]),
      )
      setCurrentBannerIndex(currentBannerIndex - 1)
    }
  }

  const goToNextBanner = () => {
    if (currentBannerIndex >= bannerIds.length - 1) {
      setCurrentItem(rotationBannersByBannerId.get(bannerIds[0]))
      setCurrentBannerIndex(0)
    } else {
      setCurrentItem(
        rotationBannersByBannerId.get(bannerIds[currentBannerIndex + 1]),
      )
      setCurrentBannerIndex(currentBannerIndex + 1)
    }
  }

  useEffect(() => {
    if (rotation.length > 0) {
      const { bannerIds, itemsByBannerId: rotationItemBannersByBannerId } =
        getRotationBannerIds(rotation)

      const bannerIdsFromBottomToTop = bannerIds.reverse()
      setBannerIds(bannerIdsFromBottomToTop)

      setRotationBannersByBannerId(rotationItemBannersByBannerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotation])

  useEffect(
    () =>
      setRotation([
        {
          type: 'case',
          item_id: random(),
          content_id: false,
          name: 'Case',
          percent: 0,
          targets: {},
          limits: {},
          cappings: [],
          items: [],
        },
      ]),
    [pageId],
  )

  useEffect(() => {
    const servRotation =
      !!rtt &&
      ((typeof rtt.rotation === 'object' && rtt.rotation.length > 0) ||
        (typeof rtt.rotation === 'string' && rtt.rotation.length)) &&
      JSON.parse(rtt.rotation)

    if (servRotation && servRotation.length) {
      setRotation(servRotation)
    }
  }, [rtt])

  useEffect(() => {
    if (r) r.setRotation(rotation)
  }, [r, rotation])

  const updateRotationId = (y) => {
    const w =
      y &&
      y.map((u) => ({
        ...u,
        item_id: random(),
        items: updateRotationId(u.items),
      }))

    return [...w]
  }

  const duplicateRotation = (r, item_id) => {
    let t = []

    const w =
      r &&
      r.map((i) => {
        if (i.item_id === item_id) {
          t = [{ ...i, item_id: random() }]
        }

        i.items = duplicateRotation(i.items, item_id)
        return i
      })

    if (t.length) {
      t[0].items = t[0] && 'items' in t[0] ? updateRotationId(t[0].items) : []
    }

    return [...w, ...t]
  }

  const addNewItem = (item) => {
    setRotation(addToRotation(rotation, item.item_id))
  }

  const removeElement = (item_id) => {
    setRotation(removeItemRotation(rotation, item_id))
  }

  const duplicate = (item_id) => {
    setRotation(duplicateRotation([...rotation], item_id))
  }

  const renderItem = ({ item, collapseIcon }) => {
    if (item.items[0] && !['case', 'retarget'].includes(item.items[0].type)) {
      // console.log('close')
    }

    return (
      <List sx={{ p: 0 }}>
        <ListItem
          size={'sm'}
          sx={{
            p: 0,
            [`& [class*="case-"]`]: {
              display: 'none',
            },
            [`&:hover [class*="case-"]`]: {
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <ListItemDecorator>
            <ListItemButton
              sx={{
                pr: '3px',
                py: 0,
                pl:
                  ['case', 'retarget'].includes(item.type) && item.items.length
                    ? '1px'
                    : 1,
                gridGap: 0,
                borderRadius: 4,
                border: '1px solid',
                borderColor: 'var(--joy-palette-divider)',
                minWidth: '260px',
                mb: 0,
                [`& > span`]: {
                  display: 'flex',
                },
                [`&:before, &:after`]: {
                  content: '""',
                  position: 'absolute',
                },
                [`&:before`]: {
                  borderBottom: '1px dashed',
                  borderColor: 'var(--joy-palette-divider)',
                  left: '-19px',
                  top: '50%',
                  width: '18px',
                },
                [`&:after`]: {
                  backgroundColor: 'var(--joy-palette-divider)',
                  left: '-22px',
                  top: 'calc(50% - 2px)',
                  width: '5px',
                  height: '5px',
                  borderRadius: '50%',
                },
              }}
              variant={'soft'}
              color="primary"
              // color={ ? 'primary' : 'danger'}
            >
              {collapseIcon}
              {typesIcon[item.type]}

              <Typography sx={{ flex: 1, fontSize: 12, ml: 1 }}>
                {item.type === 'wrapper' ? t('wrapper') : item.name}
              </Typography>

              <Chip
                sx={{ fontSize: 10, mr: 0.3, ml: 1 }}
                color="primary"
                size="sm"
                variant="outlined"
              >
                {item.item_id}
              </Chip>

              {!!(parseInt(item.percent) > 0) && (
                <Chip
                  sx={{ fontSize: 10, mr: 0.4, ml: 0.3 }}
                  color="primary"
                  size="sm"
                  variant="outlined"
                >
                  {item.percent}%
                </Chip>
              )}

              {!!item.skip && template_type === 1 && (
                <Chip
                  sx={{ fontSize: 10, mr: 0.4, ml: 0.3 }}
                  color="primary"
                  size="sm"
                  variant="outlined"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SkipNextRounded sx={{ ml: -0.2, mr: 0.2, fontSize: 15 }} />
                    {item.skip}s
                  </Box>
                </Chip>
              )}

              <CurrentTargetsTooltip item={item} />
              {item.type === 'banner' && (
                <BannerButton onClick={() => onOpenBanner(item)} />
              )}
              <ViewSettingsBtn
                onClick={(e) => {
                  setOpenTargets(e)
                  setCurrentItem(item)
                }}
                item={item}
              />
            </ListItemButton>
          </ListItemDecorator>

          {item.type === 'case' && (
            <IconButton
              sx={{ ml: 2 }}
              size="sm"
              className={'case-add-btn'}
              onClick={(e) => addNewItem(item)}
              variant="plain"
              color="primary"
            >
              <Add sx={{ fontSize: 18 }} />
            </IconButton>
          )}

          <IconButton
            sx={{ ml: item.type === 'case' ? '-10px' : 2 }}
            size="sm"
            className={'case-dub-btn'}
            variant="plain"
            color="primary"
            onClick={(e) => duplicate(item.item_id)}
          >
            <CopyAllRounded sx={{ fontSize: 18 }} />
          </IconButton>

          <IconButton
            sx={{ ml: '-10px' }}
            size="sm"
            className={'case-del-btn'}
            variant="plain"
            color="danger"
            onClick={(e) => removeElement(item.item_id)}
          >
            <DeleteRounded sx={{ fontSize: 18 }} />
          </IconButton>
        </ListItem>
      </List>
    )
  }

  return (
    <>
      <ViewSettings
        open={openTargets}
        setOpen={setOpenTargets}
        rotation={{ val: rotation, set: setRotation }}
        current={{ val: currentItem, set: setCurrentItem }}
        template_type={template_type}
      />
      <BannerProvider>
        <Banner
          open={openEditRotation}
          setOpen={setOpenEditBanner}
          bannerIds={bannerIds}
          bannerId={currentItem.content_id}
          currentIdInTree={currentItem.item_id}
          goToNextBanner={goToNextBanner}
          goToPreviousBanner={goToPreviousBanner}
        />
      </BannerProvider>
      <Sheet
        // variant='outlined'
        // color='neutral'
        sx={{
          maxWidth: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          backgroundColor: 'transparent',
          height: '100%',
          overflowY: 'auto',
          flex: 1,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'inline-flex',
            flexDirection: 'column',
            [`&  ol.nestable-list`]: {
              listStyleType: 'none',
            },
            [`& li.nestable-item`]: {
              marginBottom: '5px',
            },
            [`& .nestable-item-name`]: {
              marginBottom: '5px',
            },
            [`& .nestable > ol`]: {
              borderLeft: 'none!important',
            },
            [`& ol.nestable-list`]: {
              borderLeft: '1px dashed',
              paddingLeft: '30px',
              borderColor: 'var(--joy-palette-divider)',
            },
            [`& .nestable > ol > li:first-child > div > ul > li[data-first-child] button.case-del-btn`]:
              {
                display: 'none',
              },
          }}
        >
          {pageId !== rtt.id ? (
            <CircularProgress size="sm" />
          ) : (
            <Nestable
              items={rotation}
              onChange={(e) => {
                setRotation(e.items)
              }}
              renderCollapseIcon={(e) =>
                e.isCollapsed ? (
                  <KeyboardArrowRightRounded sx={{ fontSize: 20, mr: '2px' }} />
                ) : (
                  <KeyboardArrowDownRounded sx={{ fontSize: 20, mr: '2px' }} />
                )
              }
              childrenProp={'items'}
              idProp={'item_id'}
              renderItem={renderItem}
              confirmChange={(e) => {
                return !e.destinationParent ||
                  e.destinationParent.type === 'case'
                  ? true
                  : false
              }}
            />
          )}
        </Box>
      </Sheet>
    </>
  )
}

export default TargetContainer
