import * as React from 'react'

import {
  WebRounded,
  TranslateRounded,
  ResetTvRounded,
  DevicesOtherRounded,
  ImportantDevicesRounded,
  ShareRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../../Accord'

import Browser from './Browser'
import OperatingSystem from './OperatingSystem'
import Language from './Language'
import DeviceFormat from './DeviceFormat'
import DeviceVendor from './DeviceVendor'
import Referer from './Referer'
import { useSelector } from 'react-redux'

const UserAgent = ({ isAdvancedSearch }) => {
  const { t } = useTranslation()
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )

  return (
    <>
      <Accord
        items={
          <>
            {statParams.browser_name && (
              <AccordItem
                text={t('target_1_browser')}
                icon={<WebRounded />}
                items={<Browser isAdvancedSearch={isAdvancedSearch} />}
              />
            )}
            {statParams.os_name && (
              <AccordItem
                text={t('target_1_os')}
                icon={<ResetTvRounded />}
                items={<OperatingSystem isAdvancedSearch={isAdvancedSearch} />}
              />
            )}
            {statParams.language && (
              <AccordItem
                text={t('target_1_browser_lang')}
                icon={<TranslateRounded />}
                items={<Language />}
              />
            )}
            {statParams.device_format && (
              <AccordItem
                text={t('target_1_device')}
                icon={<DevicesOtherRounded />}
                items={<DeviceFormat />}
              />
            )}
            {statParams.device_vendor && (
              <AccordItem
                text={t('target_1_device_vendor')}
                icon={<ImportantDevicesRounded />}
                items={<DeviceVendor />}
              />
            )}
            {isAdvancedSearch && statParams.referer && (
              <AccordItem
                text={t('target_1_referer')}
                icon={<ShareRounded />}
                items={<Referer />}
              />
            )}
          </>
        }
      />
    </>
  )
}

export default UserAgent
