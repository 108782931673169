import Endpoints from '../../endpoints'
import { AxiosPromise } from 'axios'
import { axiosInstance } from '../../instance'
import {
  IStatisticFilterRequest,
  IStatisticFilterResponse,
  IStatisticBalanceFilterRequest,
  IStatisticBalanceFilterResponse,
  IStatisticFilterPublisherRequest,
} from './types'

export const filter = (
  params: IStatisticFilterRequest,
): AxiosPromise<IStatisticFilterResponse> =>
  axiosInstance.post(Endpoints.STATISTIC.FILTER, params)

export const filterBalance = (
  params: IStatisticBalanceFilterRequest,
): AxiosPromise<IStatisticBalanceFilterResponse> =>
  axiosInstance.post(Endpoints.STATISTIC.BALANCE, params)

export const filterPublisher = (
  params: IStatisticFilterPublisherRequest,
): AxiosPromise<IStatisticFilterResponse> =>
  axiosInstance.post(Endpoints.STATISTIC.PUBLISHER_FILTER, params)
