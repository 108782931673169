import React, { useEffect } from 'react'

import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/joy'
import { Add, LocationSearchingRounded } from '@mui/icons-material/'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useAppDispatch } from '../../../../../../../store'
import {
  bannerTrackerGetAll,
  setTrackers,
} from '../../../../../../../store/banners'

import Table from './Table'
import InputBar from './InputBar'
import { useSelector } from 'react-redux'
import { useBnr } from '../../../../../../hooks/banners'

const Additional = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [cookie] = useCookies(['reload'])
  const advertiser_id = parseInt(useParams().pageId)
  const banner_id = parseInt(useParams().campaignId)
  const { bnr } = useBnr()
  const bannerTrackers = useSelector(
    (state) =>
      state.banners.trackerAllData.trackersByBannerId[bnr.banner_id] || [],
  )

  useEffect(() => {
    if (advertiser_id > 0 && banner_id > 0) {
      dispatch(
        bannerTrackerGetAll({
          banner_id,
          advertiser_id,
        }),
      )
    } else {
      dispatch(setTrackers(bannerTrackers))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookie.reload, banner_id, advertiser_id, bnr.banner_id])

  return (
    <Accordion sx={{ border: 'none' }}>
      <AccordionSummary indicator={<Add />} onClick={(e) => e.preventDefault()}>
        <LocationSearchingRounded />
        <Box sx={{ flex: 1, fontSize: '13px' }}>{t('trackers_and_pixels')}</Box>
      </AccordionSummary>
      <AccordionDetails>
        <InputBar />
        <Table />
      </AccordionDetails>
    </Accordion>
  )
}

export default Additional
