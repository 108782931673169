import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  IBannersAddRequest,
  IBannersTrackerAddRequest,
} from '../../api/banners/add/types'

import {
  IBannersRemoveRequest,
  IBannersTrackerRemoveRequest,
} from '../../api/banners/delete/types'

import {
  IBannersGetAllRequest,
  IBannersTrackerGetAllRequest,
} from '../../api/banners/get/types'

import {
  IBannersSetActiveRequest,
  IBannersEditRequest,
} from '../../api/banners/edit/types'

import {
  bannerFetchStart,
  bannerAddDone,
  bannerEditDone,
  bannerDeleteDone,
  bannerGetAllDone,
  bannerSetActiveDone,
  trackerTrackerFetchStart,
  bannerTrackerAddDone,
  bannerTrackerDeleteDone,
  bannerTrackerGetAllDone,
  bannerCdnsStart,
  bannerCdnsEnd,
  bannerGetMultipleDone,
} from './reducer'

export const bannerAdd =
  (data: IBannersAddRequest) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(bannerFetchStart())
      const result = await api.bannersAdd.create(data)
      dispatch(
        bannerAddDone({
          result: { ...JSON.parse(result.config.data), ...result.data },
          success: true,
        }),
      )
      return { success: true }
    } catch (e: any) {
      const error = e?.response?.data?.errorText ?? e?.message
      dispatch(bannerAddDone({ error, success: false }))
      return { success: false, error }
    }
  }

export const bannerDel =
  (data: IBannersRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(bannerFetchStart())
      await api.bannersDel.remove(data)
      dispatch(bannerDeleteDone({ ...data, error: false }))
      return true
    } catch (e: any) {
      dispatch(bannerDeleteDone({ error: e.message }))
      return false
    }
  }

export const bannerEdit =
  (data: IBannersEditRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(bannerFetchStart())
      await api.bannersEdit.update(data)
      dispatch(bannerEditDone({ data, success: true, error: false }))
      return true
    } catch (e: any) {
      dispatch(bannerEditDone({ error: e.message, success: false }))
      return false
    }
  }

export const bannerGetAll =
  (data: IBannersGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<Object | boolean> => {
    try {
      dispatch(bannerFetchStart())
      const result = await api.bannersGet.getAll(data)
      const updatedBanners = result.data.rows.map((banner) => ({
        ...banner,
        campaign_id: JSON.parse(result.config.data).campaign_id,
      }))

      dispatch(bannerGetAllDone({ data: updatedBanners, error: false }))
      return result
    } catch (e: any) {
      dispatch(bannerGetAllDone({ error: e.message }))
      return false
    }
  }

export const setActive =
  (data: IBannersSetActiveRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      console.log(data)
      dispatch(bannerFetchStart())
      await api.bannersEdit.setActive(data)
      dispatch(bannerSetActiveDone(data))
    } catch (e: any) {
      dispatch(bannerSetActiveDone({ error: e.message }))
    }
  }

export const bannerTrackerAdd =
  (data: IBannersTrackerAddRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(trackerTrackerFetchStart())
      const result = await api.bannersAdd.trackerCreate(data)
      dispatch(
        bannerTrackerAddDone({
          tracking_id: result.data.tracking_id,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(bannerTrackerAddDone({ error: e.message, success: false }))
      return false
    }
  }

export const bannerTrackerDel =
  (data: IBannersTrackerRemoveRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(trackerTrackerFetchStart())
      await api.bannersDel.trackerRemove(data)
      dispatch(
        bannerTrackerDeleteDone({
          tracking_id: data.tracking_id,
          error: false,
          success: true,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(bannerTrackerDeleteDone({ error: e.message, success: false }))
      return false
    }
  }

export const bannerTrackerGetAll =
  (data: IBannersTrackerGetAllRequest) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(trackerTrackerFetchStart())
      const result = await api.bannersGet.trackerGetAll(data)
      dispatch(
        bannerTrackerGetAllDone({
          result: result.data,
          success: true,
          error: false,
        }),
      )
      return true
    } catch (e: any) {
      dispatch(bannerTrackerGetAllDone({ error: e.message, success: false }))
      return false
    }
  }

export const bannerGetCdns =
  () =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(bannerCdnsStart())
      const result = await api.bannersGet.cdns()
      dispatch(bannerCdnsEnd({ cdns: result.data.cdns }))
      return true
    } catch (e: any) {
      dispatch(bannerCdnsEnd({ error: e.message, cdns: [] }))
      return false
    }
  }

export const bannerGetMultipleBanners =
  (data: { ids: number[] }) =>
  async (dispatch: Dispatch<any>): Promise<boolean> => {
    try {
      dispatch(bannerFetchStart())
      const result = await api.bannersGet.getMultipleBanners(data)
      dispatch(bannerGetMultipleDone({ data: result.data, error: false }))
      return true
    } catch (e: any) {
      dispatch(bannerGetMultipleDone({ error: e.message }))
      return false
    }
  }

export const setTrackers = (trackers: any) => (dispatch: Dispatch<any>) => {
  dispatch(
    bannerTrackerGetAllDone({
      result: { rows: trackers },
      success: true,
      error: false,
    }),
  )
}
