import React, { useContext } from 'react'

import { Sheet, Box } from '@mui/joy'

import Ip from './Ip'
import UserAgent from './UserAgent'
import General from './General'
import Utility from './Utility'
import { FiltersContext } from '../../../../hocs/FiltersProvider'

const Options = () => {
  const { isAdvancedSearch, setIsAdvancedSearch, reportType, setReportType } =
    useContext(FiltersContext)

  return (
    <>
      <Sheet
        sx={{
          pt: 2,
          maxWidth: 'clamp(450px, 100dvw, 936px)',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto',
          backgroundColor: 'var(--joy-palette-background-surface)',
          borderRight: '1px solid',
          borderColor:
            'var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)))',
        }}
      >
        <Box>
          <Utility
            isAdvancedSearch={isAdvancedSearch}
            setIsAdvancedSearch={setIsAdvancedSearch}
            reportType={reportType}
            setReportType={setReportType}
          />
          <General />
          <Ip isAdvancedSearch={isAdvancedSearch} />
          <UserAgent isAdvancedSearch={isAdvancedSearch} />
        </Box>
      </Sheet>
    </>
  )
}

export default React.memo(Options)
