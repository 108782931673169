import React from 'react'

import { ReactComponent as NoAccess } from '../../../../frontend/icons/no_access.svg'
import { Box, Typography } from '@mui/joy'
import { FilterProvider } from '../../../hooks/filter'
import { TabProvider } from '../../../hooks/tab'
import { ReportProvider } from '../../../hooks/report'

import Tabs from './Tabs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Filter = () => {
  const { t } = useTranslation()

  const profile = useSelector((state) => state.auth.profileData)
  const profileData =
    !profile.isLoading && !profile.error && profile.profile
      ? profile.profile
      : null
  // accessableRolesIds: 1 - admin, 2 - manager, 4 - publisher
  const accessableRolesIds = [1, 2, 4]
  if (
    profileData &&
    !accessableRolesIds.includes(profileData.role_id) &&
    !profileData.advertisers.length
  ) {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
            Статистика
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2,
            pt: '160px',
          }}
        >
          <NoAccess />
          <Typography
            sx={{
              maxWidth: '460px',
              textAlign: 'center',
              fontWeight: 600,
              fontSize: '20px',
            }}
          >
            {t('no_access')}
          </Typography>
          <Typography sx={{ maxWidth: '460px', textAlign: 'center' }}>
            {t('no_access_message')}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box
        component="main"
        className="MainContent"
        sx={() => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          minHeight: 'calc(100dvh)',
          gap: 1,
          overflowY: 'auto',
        })}
      >
        <FilterProvider>
          <TabProvider>
            <ReportProvider>
              <Tabs />
            </ReportProvider>
          </TabProvider>
        </FilterProvider>
      </Box>
    </>
  )
}

export default Filter
