import React, { useState } from 'react'

import { useFilter } from '../../../../../hooks/filter'
import { useTranslation } from 'react-i18next'
import { ConfigProvider, DatePicker } from 'antd'
import localeRu from 'antd/locale/ru_RU'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'
const { RangePicker } = DatePicker

const DateInputs = ({ isReportTypeBalance, dateRange, setDateRange }) => {
  const { i18n } = useTranslation()
  const { updateFilter } = useFilter()

  const dateFormat = 'YYYY-MM-DD HH'

  dayjs.extend(utc)

  const [tempDateRange, setTempDateRange] = useState([
    dateRange[0] || dayjs().startOf('day'),
    dateRange[1] || dayjs().endOf('day').hour(23).minute(59).second(59),
  ])

  const reorderDates = (start, end) => {
    if (start.isAfter(end)) {
      return [end, start]
    }
    return [start, end]
  }

  const handleDatesChange = (_, dateString) => {
    const [from, to] = dateString
    if (from && to) {
      const fromDate = dayjs(from)
      const toDate = dayjs(to)

      updateFilter('date_from', fromDate.format('YYYY-MM-DD HH:mm:ss'))
      updateFilter('date_to', toDate.format('YYYY-MM-DD HH:mm:ss'))
      setDateRange([fromDate, toDate])
    }
  }

  const handleCalendarChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      let [startDate, endDate] = reorderDates(dayjs(dates[0]), dayjs(dates[1]))
      setTempDateRange([startDate, endDate])
    }
  }

  const areDateRangesEqual = (dateRange, tempDateRange) => {
    return (
      dateRange[0].isSame(tempDateRange[0]) &&
      dateRange[1].isSame(tempDateRange[1])
    )
  }

  const handleBlur = () => {
    if (areDateRangesEqual(dateRange, tempDateRange)) {
      return
    }
    if (tempDateRange[0] && tempDateRange[1]) {
      let [startDate, endDate] = [
        dayjs(tempDateRange[0]),
        dayjs(tempDateRange[1]),
      ]

      setDateRange([startDate, endDate])
      updateFilter('date_from', startDate.format('YYYY-MM-DD HH:mm:ss'))
      updateFilter('date_to', endDate.format('YYYY-MM-DD HH:mm:ss'))
    }
  }

  return (
    <>
      <ConfigProvider locale={i18n.language === 'ru' ? localeRu : {}}>
        <RangePicker
          style={{ flex: 1 }}
          value={tempDateRange}
          defaultValue={dateRange}
          format={isReportTypeBalance ? 'YYYY-MM-DD' : dateFormat}
          onChange={handleDatesChange}
          onCalendarChange={handleCalendarChange}
          onBlur={handleBlur}
          showTime={isReportTypeBalance ? false : { format: 'HH' }}
        />
      </ConfigProvider>
    </>
  )
}

export default DateInputs
