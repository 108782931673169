import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BannersState, BannerTracker } from './types'

const initialState: BannersState = {
  allData: {
    bannersById: {},
    allBannerIds: [],
    isLoading: false,
    success: false,
    error: null,
  },

  trackerAllData: {
    tracking_id: null,
    trackersByBannerId: {},
    trackers: [],
    isLoading: false,
    success: false,
    error: null,
    delete: null,
  },

  cloudData: {
    tree: [],
    isLoading: false,
    success: false,
    error: null,
  },

  uploadData: {
    url: '',
    isLoading: false,
    success: false,
    error: null,
  },

  cdnData: {
    data: [],
    isLoading: false,
    error: null,
  },
}

export const reducer = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    // Set isLoading to true
    bannerFetchStart: (state): BannersState => ({
      ...state,
      allData: {
        ...state.allData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Get All banners
    bannerGetAllDone: (
      state,
      { payload: { data, error } }: PayloadAction<any>,
    ): BannersState => {
      if (!error) {
        const bannersArray = data

        const bannersById = bannersArray.reduce(
          (acc: { [x: number]: any }, banner: { banner_id: number }) => {
            acc[banner.banner_id] = banner
            return acc
          },
          { ...state.allData.bannersById },
        )

        const allBannerIds = Object.keys(bannersById).map(Number)

        return {
          ...state,
          allData: {
            bannersById,
            allBannerIds,
            isLoading: false,
            success: true,
            error: null,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },

    // Add banner
    bannerAddDone: (
      state,
      { payload: { success, result, error } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      allData: {
        bannersById: success
          ? { ...state.allData.bannersById, [result.banner_id]: result }
          : state.allData.bannersById,
        allBannerIds: success
          ? [...state.allData.allBannerIds, result.banner_id]
          : state.allData.allBannerIds,
        isLoading: false,
        success,
        error,
      },
    }),

    // Edit banner
    bannerEditDone: (
      state,
      { payload: { success, data, error } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      allData: {
        bannersById: success
          ? {
              ...state.allData.bannersById,
              [data.banner_id]: {
                ...state.allData.bannersById[data.banner_id],
                ...data,
              },
            }
          : state.allData.bannersById,
        allBannerIds: state.allData.allBannerIds,
        isLoading: false,
        success,
        error,
      },
    }),

    // Delete banner
    bannerDeleteDone: (
      state,
      { payload: { banner_id, error } }: PayloadAction<any>,
    ): BannersState => {
      if (!error) {
        const { [banner_id]: _, ...newById } = state.allData.bannersById
        return {
          ...state,
          allData: {
            bannersById: newById,
            allBannerIds: state.allData.allBannerIds.filter(
              (id) => id !== banner_id,
            ),
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: {
            ...state.allData,
            isLoading: false,
            success: false,
            error,
          },
        }
      }
    },

    // Set active
    bannerSetActiveDone: (
      state,
      { payload: { error, banner_id, active } }: PayloadAction<any>,
    ): BannersState => {
      if (!error) {
        return {
          ...state,
          allData: {
            bannersById: {
              ...state.allData.bannersById,
              [banner_id]: {
                ...state.allData.bannersById[banner_id],
                active,
              },
            },
            allBannerIds: state.allData.allBannerIds,
            isLoading: false,
            success: true,
            error: false,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },

    // Set isLoading to true (tracker)
    trackerTrackerFetchStart: (state): BannersState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: true,
        success: false,
        error: false,
      },
    }),

    // Add banner tracker
    bannerTrackerAddDone: (
      state,
      { payload: { tracking_id, error, success } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: false,
        success,
        error,
        tracking_id: success ? tracking_id : state.trackerAllData.tracking_id,
      },
    }),

    // Delete banner tracker
    bannerTrackerDeleteDone: (
      state,
      { payload: { error, success, tracking_id } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        trackers:
          success && state.trackerAllData.trackers
            ? state.trackerAllData.trackers.filter(
                (tracker) => tracker.tracking_id !== tracking_id,
              )
            : state.trackerAllData.trackers,
        isLoading: false,
        success,
        error,
      },
    }),

    // Get All banner trackers
    bannerTrackerGetAllDone: (
      state,
      { payload: { error, success, result } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      trackerAllData: {
        ...state.trackerAllData,
        isLoading: false,
        success,
        error,
        delete: success ? result.delete : state.trackerAllData.delete,
        trackers: success ? result.rows : state.trackerAllData.trackers,
      },
    }),

    bannerCdnsStart: (state): BannersState => ({
      ...state,
      cdnData: { data: [], isLoading: true, error: false },
    }),

    bannerCdnsEnd: (
      state,
      { payload: { cdns, error = null } }: PayloadAction<any>,
    ): BannersState => ({
      ...state,
      cdnData: { data: cdns, isLoading: false, error },
    }),

    bannerGetMultipleDone: (
      state,
      { payload: { data, error } }: PayloadAction<any>,
    ): BannersState => {
      if (!error) {
        const { banners, trackers } = data

        const bannersById = banners.reduce(
          (acc: { [x: number]: any }, banner: { banner_id: number }) => {
            acc[banner.banner_id] = banner
            return acc
          },
          { ...state.allData.bannersById },
        )

        const allBannerIds = Object.keys(bannersById).map(Number)

        const trackersById = trackers.reduce(
          (
            acc: { [key: number]: BannerTracker[] },
            tracker: BannerTracker & { banner_id: number },
          ) => {
            acc[tracker.banner_id] = acc[tracker.banner_id] || []
            acc[tracker.banner_id].push(tracker)
            return acc
          },
          { ...state.trackerAllData.trackersByBannerId },
        )

        return {
          ...state,
          allData: {
            bannersById,
            allBannerIds,
            isLoading: false,
            success: true,
            error: null,
          },
          trackerAllData: {
            ...state.trackerAllData,
            trackersByBannerId: trackersById,
          },
        }
      } else {
        return {
          ...state,
          allData: { ...state.allData, error, success: false },
        }
      }
    },
  },
})

export const {
  bannerFetchStart,
  bannerAddDone,
  bannerEditDone,
  bannerDeleteDone,
  bannerGetAllDone,
  bannerSetActiveDone,
  trackerTrackerFetchStart,
  bannerTrackerAddDone,
  bannerTrackerDeleteDone,
  bannerTrackerGetAllDone,
  bannerCdnsStart,
  bannerCdnsEnd,
  bannerGetMultipleDone,
} = reducer.actions

export default reducer.reducer
