import React from 'react'

import { Box } from '@mui/joy'
import { Button, Dropdown } from 'antd'
import { useReport } from '../../../../../../hooks/report'
import { TuneRounded, Done } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const defaultColumns = [
  'count',
  'clicks',
  'ctr',
  'unique',
  'frequency',
  'spend',
]

const publisherDefaultColumns = ['count', 'unique', 'frequency', 'spend']

const Settings = ({ name }) => {
  const { t } = useTranslation()
  const groupByText = t('group_by', { returnObjects: true })
  const user_role = useSelector(
    (state) => state.auth.profileData.profile?.role_id,
  )

  const shownColumns =
    user_role === 4 ? publisherDefaultColumns : defaultColumns

  const { report, replaceOption } = useReport()
  const { options: { columns = [], unique = '0', group_by = [] } = {} } =
    report[name] || {}

  const updateColumn = ({ value, checked }) => {
    const column = !checked
      ? [...columns, value]
      : columns.filter((text) => text !== value)

    replaceOption({ name, key: 'columns', value: column })
  }

  const doneIcon = ({ sx = {} }) => (
    <Done
      fontSize="md"
      color="primary"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '4px',
        zIndex: 2,
        pointerEvents: 'none',
        ...sx,
      }}
    />
  )

  const onClick = (item) => {
    const checked = columns.includes(item)
    updateColumn({ value: item, checked })
  }

  const items = () => {
    const list = []

    const btn = ({ key, text }) => {
      return (
        <Box
          onClick={() => onClick(key)}
          sx={{
            gap: 0.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {doneIcon({
            sx: { opacity: columns.includes(key) ? 0 : 1 },
          })}
          {text}
        </Box>
      )
    }

    group_by.forEach((item, key) => {
      list.push({
        key: item,
        label: btn({ key: `customField${key}`, text: groupByText[item] }),
      })
    })

    list.push({
      type: 'divider',
    })

    shownColumns.forEach((item) => {
      if (
        (unique === '1' && ['unique', 'frequency'].includes(item)) ||
        !['unique', 'frequency'].includes(item)
      ) {
        list.push({
          key: item,
          label: btn({ key: item, text: t(item) }),
        })
      }
    })

    return list
  }

  return (
    <>
      <Dropdown arrow menu={{ items: items() }} placement="bottomLeft">
        <Button
          ghost
          style={{
            paddingLeft: '4px',
            paddingRight: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          size={'small'}
        >
          <TuneRounded sx={{ fontSize: 15 }} />
        </Button>
      </Dropdown>
    </>
  )
}

export default Settings
