import * as React from 'react'

import {
  SourceRounded,
  DisplaySettingsRounded,
  AssignmentIndRounded,
  BookmarksRounded,
  DescriptionRounded,
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import { Accord, AccordItem } from '../../Accord'
import { useSelector } from 'react-redux'

import Advertisers from './Advertisers'
import Custom from './Custom'
import Publishers from './Publishers'
import Category from './Category'
import Templates from './Templates'

const General = () => {
  const { t } = useTranslation()
  const text = t('sidebar_navigation', { returnObjects: true })
  const statParams = useSelector(
    (state) => state.auth.profileData.profile.statistic_params,
  )
  const user_role = useSelector(
    (state) => state.auth.profileData.profile.role_id,
  )

  return (
    <>
      <Accord
        items={
          <>
            {statParams.banner && (
              <AccordItem
                text={`${text['advertisers']} / ${t('campaigns')} / ${t(
                  'creatives',
                )}`}
                icon={<SourceRounded />}
                items={<Advertisers />}
              />
            )}
            {statParams.category && (
              <AccordItem
                text={text['categories']}
                icon={<BookmarksRounded />}
                items={<Category />}
              />
            )}
            {statParams.zone && (
              <AccordItem
                text={`${text['publishers']} / ${t('target_2_zones')}`}
                icon={<AssignmentIndRounded />}
                items={<Publishers />}
              />
            )}
            {user_role !== 4 && (statParams.CPhost || statParams.CPdomain) && (
              <AccordItem
                text={t('target_1_custom')}
                icon={<DisplaySettingsRounded />}
                items={<Custom />}
              />
            )}
            {statParams.template && (
              <AccordItem
                text={t('creative_type')}
                icon={<DescriptionRounded />}
                items={<Templates />}
                last={1}
              />
            )}
          </>
        }
      />
    </>
  )
}

export default General
