import { Dispatch } from '@reduxjs/toolkit'
import api from '../../api'

import {
  IStatisticFilterRequest,
  IStatisticBalanceFilterRequest,
  IStatisticFilterPublisherRequest,
} from '../../api/statistic/get/types'
import {
  statisticGetStart,
  statisticGetSuccess,
  statisticGetFailure,
  statisticBalanceGetStart,
  statisticGetBalanceSuccess,
  statisticGetBalanceFailure,
  statisticPublisherGetStart,
  statisticPublisherFailure,
  statisticPublisherSuccess,
} from './reducer'

export const filterRequest =
  (data: IStatisticFilterRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(statisticGetStart())
      const result = await api.statisticGet.filter(data)
      dispatch(statisticGetSuccess({ data: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(statisticGetFailure(e.message))
      return false
    }
  }

export const filterTypeBalanceRequest =
  (data: IStatisticBalanceFilterRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(statisticBalanceGetStart())
      const result = await api.statisticGet.filterBalance(data)
      dispatch(statisticGetBalanceSuccess(result.data.reports))
      return result
    } catch (e: any) {
      dispatch(statisticGetBalanceFailure(e.message))
      return e
    }
  }

export const filterPublisherRequest =
  (data: IStatisticFilterPublisherRequest) =>
  async (dispatch: Dispatch<any>): Promise<any> => {
    try {
      dispatch(statisticPublisherGetStart())
      const result = await api.statisticGet.filterPublisher(data)
      dispatch(statisticPublisherSuccess({ data: result.data }))
      return result.data
    } catch (e: any) {
      dispatch(statisticPublisherFailure(e.message))
      return false
    }
  }
