import React from 'react'
import Box from '@mui/joy/Box'
import Routers from './Routers'

const Users = () => (
  <Box
    component="main"
    className="MainContent"
    sx={() => ({
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      minWidth: 0,
      height: 'calc(100dvh)',
      gap: 1,
      overflowY: 'auto',
    })}
  >
    <Routers />
  </Box>
)

export default Users
